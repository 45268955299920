// @subframe/sync-disable

"use client";

import React from "react";
import * as SubframeCore from "@subframe/core";

interface PaymentMethodCtaRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  icon?: SubframeCore.IconName;
  text?: React.ReactNode;
  selected?: boolean;
  className?: string;
}

const PaymentMethodCtaRoot = React.forwardRef<
  HTMLElement,
  PaymentMethodCtaRootProps
>(function PaymentMethodCtaRoot(
  {
    icon = "FeatherCreditCard",
    text,
    selected = false,
    className,
    ...otherProps
  }: PaymentMethodCtaRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/9225c641 flex h-14 w-full cursor-pointer items-center gap-4 rounded-md border border-solid border-neutral-900 bg-white px-3 py-2 hover:border hover:border-solid hover:border-neutral-500 hover:bg-white active:bg-neutral-900",
        { "!bg-neutral-900": selected },
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex items-center justify-center gap-2 rounded-md border border-solid border-neutral-50 bg-white px-2 py-1">
        <SubframeCore.Icon
          className="text-body font-body text-default-font"
          name={icon}
        />
      </div>
      {text ? (
        <span
          className={SubframeCore.twClassNames(
            "text-body-bold font-body-bold text-neutral-900 text-center group-active/9225c641:text-white",
            { "text-white": selected }
          )}
        >
          {text}
        </span>
      ) : null}
    </div>
  );
});

export const PaymentMethodCta = PaymentMethodCtaRoot;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { RootState } from "./store";

export interface AccountSliceState {
  id: number;
  name: string;
  email: string;
  description: string;
  employees: any[];
  services: any[];
  key: string;
  logo: string;
  landingImages: string[];
  selectedLandingImageLink: string;
  serviceProviders: any[];
  shops: any[];
  bookingLink: string;
  archivedAt?: string;
  requireCreditCardForBooking: boolean;
  enableCashPaymentForBooking: boolean;
  consentEnabled: boolean;
  consentForm: any;
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = {} as AccountSliceState;

export const getAccount = createAsyncThunk(
  "account/getAccountStatus",
  async ({ nameKey }: { nameKey: string }, thunkAPI) => {
    try {
      const { data } = await http.get(`/v1/accounts/public_show`, {
        params: {
          nameKey,
          snakeCase: true,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const accountSearch = createAsyncThunk(
  "account/accountSearch",
  async ({ query }: { query: string }, thunkAPI) => {
    try {
      const { data } = await http.get(
        `/v1/accounts/public_index_search?query=${query}`
      );
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkBlacklist = createAsyncThunk(
  "account/checkBlacklist",
  async (
    {
      identifier,
      identifierType,
      accountId,
    }: { identifier: string; identifierType?: string; accountId?: number },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/accounts/check_blacklist`, {
        identifier,
        identifierType: identifierType || "email",
        accountId,
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccount.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectAccount = (state: RootState) => state.account;
